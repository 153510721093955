import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "react-lazy-load-image-component/src/effects/blur.css"

// import mouseWheel from "../images/mouse-wheel.gif"

import Slide from "react-reveal/Slide"
import Flip from "react-reveal/Flip"

import applicationImg from "../images/loan-process/application.svg"
import preApprovalImg from "../images/loan-process/pre-approval.svg"
import findNewHomeImg from "../images/loan-process/findNewHome.svg"
import disclosuresImg from "../images/loan-process/disclosures.svg"
import inspectionImg from "../images/loan-process/inspection.svg"
import processingImg from "../images/loan-process/processing.svg"
import clearToCloseImg from "../images/loan-process/clear-to-close.svg"
import closingDayImg from "../images/loan-process/closing-day.svg"

const LoanProcess = () => (
  <Layout>
    <SEO title="Loan Process" />
    <div className="loan-process-wrapper">
      {/* <div className="container-fluid hero-section">
        <div className="row">
          <div className="col-md-5 vertical-align d-none-mobile">
            <div className="brandstamp">
              <h1>Our Process</h1>
              <p className=" pb-3 pt-3">
                At Mission Mortgage Colorado, you don't just get a mortgage, you
                get proven results. We've streamlined our process to ensure that
                buying your home is as smooth and stress-free as possible.
                <br />
              </p>
            </div>
          </div>
          <div className="col-md-7 lp-hero-img vertical-align">
            <div className="brandstamp-mobile text-white">
              <h1>Our Process</h1>
              <p className="mb-5">
                At Mission Mortgage Colorado, you don't just get a mortgage, you
                get proven results. We've streamlined our process to ensure that
                buying your home is as smooth and stress-free as possible.
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="col-lg-12 text-center scroll-down">
        <img className="mouse-wheel" src={mouseWheel} alt="Scroll Down" />
      </div> */}
      <div className="hero-lp-wrapper">
        <div className="container">
          <div className="text-center">
            <h2 className="text-white">Our Process</h2>
            <p className="pb-3 pt-3 text-white">
              At Mission Mortgage Colorado, you don't just get a mortgage,
              <br />
              you get proven results. We've streamlined our process to ensure
              <br />
              that buying your home is as smooth and stress-free as possible.
            </p>
          </div>
        </div>
      </div>

      <div className="content-wrapper" style={{ padding: "5rem 0" }}>
        <div className="container">
          <div className="row flex-column-reverse flex-md-row align-items-center">
            <div className="col-lg-6">
              <Slide left duration={1500}>
                <div
                  className="card apply-container mt-5"
                  style={{ border: "none" }}
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-3">
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          backgroundColor: "#61BACA",
                          width: 30,
                          height: 30,
                          borderRadius: "50%",
                          marginRight: 10,
                        }}
                      >
                        <span className="card-text mt-0 text-white font-weight-bold">
                          1
                        </span>
                      </div>
                      <h5 className="card-title m-0">Application</h5>
                    </div>
                    <p className="card-text">
                      This is the first step in the loan process. The goal of
                      our application is to gather as much information as
                      possible up front. You'll work with your loan officer to
                      determine the best possible loan to fit your situation
                      now, while planning for your future goals too.
                    </p>
                  </div>
                </div>
              </Slide>
            </div>
            <div className="col-lg-6 text-center mt-md-5">
              <Flip right duration={1500}>
                <div
                  className="step-img-wrapper d-flex align-items-center justify-content-center"
                  style={{
                    width: "200px",
                    height: "200px",
                    borderRadius: "50%",
                    boxShadow: "0 0 10px 0 #a19fcf",
                    border: "2px solid #02a5b9",
                    margin: "0 auto",
                  }}
                >
                  <img
                    src={applicationImg}
                    alt="Application"
                    style={{ width: "150px" }}
                  />
                </div>
              </Flip>
            </div>
          </div>
          <div className="row align-items-center mt-5">
            <div className="col-lg-6 text-center mt-md-5">
              <Flip left duration={1500}>
                <div
                  className="step-img-wrapper d-flex align-items-center justify-content-center"
                  style={{
                    width: "200px",
                    height: "200px",
                    borderRadius: "50%",
                    boxShadow: "0 0 10px 0 #a19fcf",
                    border: "2px solid #02a5b9",
                    margin: "0 auto",
                  }}
                >
                  <img
                    src={preApprovalImg}
                    alt="pre-approval"
                    style={{ width: "150px" }}
                  />
                </div>
              </Flip>
            </div>
            <div className="col-lg-6">
              <Slide right duration={1500}>
                <div
                  className="card apply-container mt-5"
                  style={{ border: "none" }}
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-3">
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          backgroundColor: "#61BACA",
                          width: 30,
                          height: 30,
                          borderRadius: "50%",
                          marginRight: 10,
                        }}
                      >
                        <span className="card-text mt-0 text-white font-weight-bold">
                          2
                        </span>
                      </div>
                      <h5 className="card-title m-0">Pre-Approval</h5>
                    </div>
                    <p className="card-text">
                      We'll run the numbers and let you know how much house you
                      can afford! After you're approved, We'll solidify your
                      approval by documenting your income and assets. This can
                      be done with W2's, paystubs, and bank statements, or it
                      can all be done electronically without any paperwork
                      involved.
                    </p>
                  </div>
                </div>
              </Slide>
            </div>
          </div>
          <div className="row flex-column-reverse flex-md-row align-items-center mt-5">
            <div className="col-lg-6">
              <Slide left duration={1500}>
                <div
                  className="card apply-container mt-5"
                  style={{ border: "none" }}
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-3">
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          backgroundColor: "#61BACA",
                          width: 30,
                          height: 30,
                          borderRadius: "50%",
                          marginRight: 10,
                        }}
                      >
                        <span className="card-text mt-0 text-white font-weight-bold">
                          3
                        </span>
                      </div>
                      <h5 className="card-title m-0">Find Your New Home</h5>
                    </div>
                    <p className="card-text">
                      You can now find your dream home! We'll provide approval
                      documents that you can use to go under contract, and lock
                      down your property. Once you do, it's on to step 4.
                    </p>
                  </div>
                </div>
              </Slide>
            </div>
            <div className="col-lg-6 text-center mt-md-5">
              <Flip right duration={1500}>
                <div
                  className="step-img-wrapper d-flex align-items-center justify-content-center"
                  style={{
                    width: "200px",
                    height: "200px",
                    borderRadius: "50%",
                    boxShadow: "0 0 10px 0 #a19fcf",
                    border: "2px solid #02a5b9",
                    margin: "0 auto",
                  }}
                >
                  <img
                    src={findNewHomeImg}
                    alt="find new home"
                    style={{ width: "150px" }}
                  />
                </div>
              </Flip>
            </div>
          </div>
          <div className="row align-items-center mt-5">
            <div className="col-lg-6 text-center mt-md-5">
              <Flip left duration={1500}>
                <div
                  className="step-img-wrapper d-flex align-items-center justify-content-center"
                  style={{
                    width: "200px",
                    height: "200px",
                    borderRadius: "50%",
                    boxShadow: "0 0 10px 0 #a19fcf",
                    border: "2px solid #02a5b9",
                    margin: "0 auto",
                  }}
                >
                  <img
                    src={disclosuresImg}
                    alt="Disclosures"
                    style={{ width: "150px" }}
                  />
                </div>
              </Flip>
            </div>
            <div className="col-lg-6">
              <Slide right duration={1500}>
                <div
                  className="card apply-container mt-5"
                  style={{ border: "none" }}
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-3">
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          backgroundColor: "#61BACA",
                          width: 30,
                          height: 30,
                          borderRadius: "50%",
                          marginRight: 10,
                        }}
                      >
                        <span className="card-text mt-0 text-white font-weight-bold">
                          4
                        </span>
                      </div>
                      <h5 className="card-title m-0">Disclosures</h5>
                    </div>
                    <p className="card-text">
                      This step is easy, but very important. You will receive
                      and sign an electronic disclosure package which explains
                      your loan product and details.
                    </p>
                  </div>
                </div>
              </Slide>
            </div>
          </div>
          <div className="row flex-column-reverse flex-md-row align-items-center mt-5">
            <div className="col-lg-6">
              <Slide left duration={1500}>
                <div
                  className="card apply-container mt-5"
                  style={{ border: "none" }}
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-3">
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          backgroundColor: "#61BACA",
                          width: 30,
                          height: 30,
                          borderRadius: "50%",
                          marginRight: 10,
                        }}
                      >
                        <span className="card-text mt-0 text-white font-weight-bold">
                          5
                        </span>
                      </div>
                      <h5 className="card-title m-0">
                        Inspection and Appraisal
                      </h5>
                    </div>
                    <p className="card-text">
                      Your real estate agent will help you set up a home
                      inspection. Once that is complete, and your disclosure
                      package signed, we can order your appraisal. The appraiser
                      provides an estimate of value, which is an important part
                      of the loan process.
                    </p>
                  </div>
                </div>
              </Slide>
            </div>
            <div className="col-lg-6 text-center mt-md-5">
              <Flip right duration={1500}>
                <div
                  className="step-img-wrapper d-flex align-items-center justify-content-center"
                  style={{
                    width: "200px",
                    height: "200px",
                    borderRadius: "50%",
                    boxShadow: "0 0 10px 0 #a19fcf",
                    border: "2px solid #02a5b9",
                    margin: "0 auto",
                  }}
                >
                  <img
                    src={inspectionImg}
                    alt="Inspection and Appraisal"
                    style={{ width: "150px" }}
                  />
                </div>
              </Flip>
            </div>
          </div>
          <div className="row align-items-center mt-5">
            <div className="col-lg-6 text-center mt-md-5">
              <Flip left duration={1500}>
                <div
                  className="step-img-wrapper d-flex align-items-center justify-content-center"
                  style={{
                    width: "200px",
                    height: "200px",
                    borderRadius: "50%",
                    boxShadow: "0 0 10px 0 #a19fcf",
                    border: "2px solid #02a5b9",
                    margin: "0 auto",
                  }}
                >
                  <img
                    src={processingImg}
                    alt="Processing and Underwriting"
                    style={{ width: "150px" }}
                  />
                </div>
              </Flip>
            </div>
            <div className="col-lg-6">
              <Slide right duration={1500}>
                <div
                  className="card apply-container mt-5"
                  style={{ border: "none" }}
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-3">
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          backgroundColor: "#61BACA",
                          width: 30,
                          height: 30,
                          borderRadius: "50%",
                          marginRight: 10,
                        }}
                      >
                        <span className="card-text mt-0 text-white font-weight-bold">
                          6
                        </span>
                      </div>
                      <h5 className="card-title m-0">
                        Processing & Underwriting
                      </h5>
                    </div>
                    <p className="card-text">
                      While waiting for the appraisal, we will be working to
                      solidify the rest of your loan approval. We'll order
                      verifications, finalize income and assets, confirm home
                      owners insurance, and clear title work. You'll work
                      closely with your loan officer, so that everything will be
                      ready for Final Loan Approval once your appraisal comes
                      in.
                    </p>
                  </div>
                </div>
              </Slide>
            </div>
          </div>
          <div className="row flex-column-reverse flex-md-row align-items-center mt-5">
            <div className="col-lg-6">
              <Slide left duration={1500}>
                <div
                  className="card apply-container mt-5"
                  style={{ border: "none" }}
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-3">
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          backgroundColor: "#61BACA",
                          width: 30,
                          height: 30,
                          borderRadius: "50%",
                          marginRight: 10,
                        }}
                      >
                        <span className="card-text mt-0 text-white font-weight-bold">
                          7
                        </span>
                      </div>
                      <h5 className="card-title m-0">Clear to Close</h5>
                    </div>
                    <p className="card-text">
                      Congratulations, you did it!! When you reach this point,
                      it means that you have Final Loan Approval and have
                      satisfied everything you need to acquire your loan. You'll
                      receive your Closing Disclosure, to confirm the amount for
                      Closing. It's time to do The Happy Dance!!
                    </p>
                  </div>
                </div>
              </Slide>
            </div>
            <div className="col-lg-6 text-center mt-md-5">
              <Flip right duration={1500}>
                <div
                  className="step-img-wrapper d-flex align-items-center justify-content-center"
                  style={{
                    width: "200px",
                    height: "200px",
                    borderRadius: "50%",
                    boxShadow: "0 0 10px 0 #a19fcf",
                    border: "2px solid #02a5b9",
                    margin: "0 auto",
                  }}
                >
                  <img
                    src={clearToCloseImg}
                    alt="Clear to Close"
                    style={{ width: "150px" }}
                  />
                </div>
              </Flip>
            </div>
          </div>
          <div className="row align-items-center mt-5">
            <div className="col-lg-6 text-center mt-md-5">
              <Flip left duration={1500}>
                <div
                  className="step-img-wrapper d-flex align-items-center justify-content-center"
                  style={{
                    width: "200px",
                    height: "200px",
                    borderRadius: "50%",
                    boxShadow: "0 0 10px 0 #a19fcf",
                    border: "2px solid #02a5b9",
                    margin: "0 auto",
                  }}
                >
                  <img
                    src={closingDayImg}
                    alt="Closing Day"
                    style={{ width: "150px" }}
                  />
                </div>
              </Flip>
            </div>
            <div className="col-lg-6">
              <Slide right duration={1500}>
                <div
                  className="card apply-container mt-5"
                  style={{ border: "none" }}
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-3">
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          backgroundColor: "#61BACA",
                          width: 30,
                          height: 30,
                          borderRadius: "50%",
                          marginRight: 10,
                        }}
                      >
                        <span className="card-text mt-0 text-white font-weight-bold">
                          8
                        </span>
                      </div>
                      <h5 className="card-title m-0">Closing Day</h5>
                    </div>
                    <p className="card-text">
                      You'll sign your final paperwork and receive the keys to
                      your new home!!! Your loan officer will be there with you,
                      and always available after. At Mission Mortgage Colorado,
                      we stay in touch with you. We're here any time you have
                      questions, and to always make sure your financing fits
                      your life and goals.
                    </p>
                  </div>
                </div>
              </Slide>
            </div>
          </div>
        </div>
      </div>

      <div className="apply-section">
        <div className="protruded-gradient"></div>
        <div className="container">
          <div className="apply-container">
            <div className="row align-items-center">
              <div className="col-md-8">
                <div>
                  <h2 className="mb-2 mt-3">
                    Let's get started
                    <br />
                  </h2>
                  <p className="pb-4">
                    Getting pre-approved can be quick and easy. Start the
                    process today!
                    <br />
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div className="col">
                    <a
                      href="https://plus.preapp1003.com/Darlene-Franklin"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn-global w-100 mb-3"
                    >
                      Apply Now
                    </a>
                  </div>
                  <div className="col">
                    <Link
                      to="/loan-process"
                      className="btn-global-inverted w-100 text-center"
                      style={{ padding: "10px 20px" }}
                    >
                      Our Process
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default LoanProcess
